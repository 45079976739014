/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-05
 */

// Add third-party dependencies.
import { Box, Typography } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';

/**
 * Home component.
 * @component
 * @example
 * return (
 *   <Home />
 * )
 * @returns {React.ReactElement} component.
 */
const Home = () => {
  // Render component.
  return (
    <Box flexGrow={1} p={2}>
      <Typography variant="h1" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <HomeIcon sx={{ fontSize: 34, mr: 1 }} />
        Home
      </Typography>
    </Box>
  );
};

// Export component.
export default Home;
