/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-03
 */

// Add third-party dependencies.
import { useContext } from 'react';
import { Box, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

// Add local dependencies.
import { DisplayContext } from '../../contexts/DisplayContext';
import LoginButton from '../Auth/LoginButton';
import Link from '../App/Link';

/**
 * Header component.
 * @component
 * @example
 * return (
 *   <Header />
 * )
 * @returns {React.ReactElement} component.
 */
const Header = () => {
  // Context hooks.
  const { drawerOpen, setDrawerOpen } = useContext(DisplayContext);

  // Render component.
  return (
    <Box display="flex" component="header">
      <AppBar
        component="div"
        color="primary"
        position="absolute"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) =>
            theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.standard,
            }),
          ...(drawerOpen && {
            ml: 30,
            width: `calc(100% - ${258}px)`,
          }),
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open navigation drawer"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              ml: -1,
              visibility: 'visible',
              transition: (theme) =>
                theme.transitions.create(['margin'], {
                  easing: theme.transitions.easing.easeInOut,
                  duration: theme.transitions.duration.standard,
                }),
              ...(drawerOpen && {
                ml: -4,
                visibility: 'hidden',
              }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography componet="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Link to="/" ariaLabel='="tools.dance' underline="none">
              tools.dance
            </Link>
          </Typography>
          <LoginButton />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

// Export component.
export default Header;
