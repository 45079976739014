/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-01
 */

// Add third-party dependencies.
import { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create context.
export const DisplayContext = createContext();

/**
 * DisplayContextProvider component.
 * @example
 * const children = (<div>Hello</div>)
 * return (
 *   <DisplayContextProvider children={children} \>
 * )
 * @param {ReactChildren} children - child components.
 * @returns {React.ReactElement} component.
 */
const DisplayContextProvider = ({ children }) => {
  // State hooks.
  const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
  // const [darkMode, setDarkMode] = useState(localStorage.getItem('darkMode') === 'false');
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Effect hooks.
  useEffect(() => {
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  /**
   * Toggle dark model.
   */
  const toggleDarkMode = () => setDarkMode(!darkMode);

  // Memo hooks.
  const value = useMemo(() => ({ darkMode, toggleDarkMode, drawerOpen, setDrawerOpen }), [darkMode, drawerOpen]);

  // Render component.
  return <DisplayContext.Provider value={value}>{children}</DisplayContext.Provider>;
};

// Prop types.
DisplayContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
};

// Export component.
export default DisplayContextProvider;
