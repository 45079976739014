/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-01
 */

// Add third-party dependencies.
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

// Add local dependencies.
import { DisplayContext } from '../../contexts/DisplayContext';
import { light, dark } from '../../themes';
import Cradle from './Cradle';
import Router from '../App/Router';

/**
 * Site component.
 * @component
 * @example
 * return (
 *   <Site />
 * )
 * @returns {React.ReactElement} component.
 */
const Site = () => {
  // Context hooks.
  const { darkMode } = useContext(DisplayContext);

  // Router hooks.
  const [searchParams] = useSearchParams();
  const cradled = searchParams.get('cradled');

  // Themes.
  const themeLight = createTheme(light);
  const themeDark = createTheme(dark);
  const theme = darkMode ? themeDark : themeLight;

  // Render component.
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {cradled !== 'no' ? (
        <Cradle>
          <Router />
        </Cradle>
      ) : (
        <Router />
      )}
    </ThemeProvider>
  );
};

// Export component.
export default Site;
