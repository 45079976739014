/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-03
 */

// Add third-party dependencies.
import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create context.
export const AuthContext = createContext();

/**
 * AuthContextProvider component.
 * @example
 * const children = (<div>Hello</div>)
 * return (
 *   <AuthContextProvider children={children} \>
 * )
 * @param {ReactChildren} children - child components.
 * @returns {React.ReactElement} component.
 */
const AuthContextProvider = ({ children }) => {
  // State hooks.
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Memo hooks.
  const value = useMemo(() => ({ isAuthenticated, setIsAuthenticated }), [isAuthenticated]);

  // Render component.
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Prop types.
AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
};

// Export component.
export default AuthContextProvider;
