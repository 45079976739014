/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-05-27
 */

// Add third-party dependencies.
import { BrowserRouter } from 'react-router-dom';

// Add local dependencies.
import DisplayContextProvider from '../../contexts/DisplayContext';
import AuthContextProvider from '../../contexts/AuthContext';
import Site from '../Site';

const App = () => {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <DisplayContextProvider>
          <Site />
        </DisplayContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  );
};

export default App;
