/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-07
 */

// Add third-party dependencies.
import { Box } from '@mui/material';

/**
 * Venn component.
 * @component
 * @example
 * return (
 *   <Venn />
 * )
 * @returns {React.ReactElement} component.
 */
const Venn = () => {
  // Render component.
  return (
    <Box component="svg" viewBox="0, 0 600 400">
      <Box
        component="circle"
        cx="200"
        cy="200"
        r="200"
        sx={{
          fill: (theme) => theme.palette.info.dark,
          opacity: 0.5,
        }}
      />
      <Box
        component="circle"
        cx="400"
        cy="200"
        r="200"
        sx={{
          fill: (theme) => theme.palette.error.dark,
          opacity: 0.5,
        }}
      />
      <Box
        component="text"
        x="100"
        y="200"
        dy="-5"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        Something
      </Box>
      <Box
        component="text"
        x="100"
        y="200"
        dy="30"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        Broke
      </Box>
      <Box
        component="text"
        x="300"
        y="200"
        dy="-5"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        404
      </Box>
      <Box
        component="text"
        x="300"
        y="200"
        dy="30"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        Page
      </Box>
      <Box
        component="text"
        x="500"
        y="200"
        dy="-5"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        You Can&apos;t
      </Box>
      <Box
        component="text"
        x="500"
        y="200"
        dy="30"
        sx={{ fill: (theme) => theme.palette.info.contrastText, fontSize: '2rem', textAnchor: 'middle' }}
      >
        Type
      </Box>
    </Box>
  );
};

// Export component.
export default Venn;
