/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-11
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

// Add local dependencies.
import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';

// AppBar spacer.
const AppBarSpacer = styled(Box)(({ theme }) => theme.mixins.toolbar);

/**
 * Cradle component.
 * @component
 * @example
 * return (
 *   <Cradle />
 * )
 * @returns {React.ReactElement} component.
 */
const Cradle = ({ children }) => {
  // Render component.
  return (
    <Box
      display="flex"
      minHeight="100vh"
      flexDirection="column"
      sx={{ bgcolor: 'background.default', color: 'text.primary' }}
    >
      <Header />
      <Box display="flex" flexGrow={1}>
        <Nav />
        <Box>
          <AppBarSpacer />
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

// Prop types.
Cradle.propTypes = {
  children: PropTypes.node.isRequired,
};

// Export component.
export default Cradle;
