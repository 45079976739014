/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-05
 */

// Add third-party dependencies.
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

/**
 * Link component.
 * @component
 * @example
 * return (
 *   <Link
 *     to="https://google.com"
 *     ariaLabel="Google"
 *     underline="none"
 *     variant="body2">
 *       Google
 *   </Link>
 * )
 * @returns {React.ReactElement} component.
 */
const Link = ({ to, ariaLabel, underline, children, variant }) => {
  // Check if "to" has "http" in it.
  const externalLink = to.includes('http');

  // Render component.
  if (externalLink)
    return (
      <MuiLink color="inherit" href={to} aria-label={ariaLabel} underline={underline} variant={variant}>
        {children}
      </MuiLink>
    );
  if (children)
    return (
      <MuiLink
        color="inherit"
        component={RouterLink}
        to={to}
        aria-label={ariaLabel}
        underline={underline}
        variant={variant}
      >
        {children}
      </MuiLink>
    );
  return <MuiLink color="inherit" to={to} aria-label={ariaLabel} underline={underline} variant={variant} />;
};

// Prop types.
Link.propTypes = {
  to: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  underline: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
};

// Default values for props types.
Link.defaultProps = {
  ariaLabel: 'hyperlink',
  underline: 'always',
  children: null,
  variant: null,
};

// Export component.
export default Link;
