/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-01
 */

// Add third-party dependencies.
import React from 'react';
import { createRoot } from 'react-dom/client';

// Add local dependencies.
import App from './components/App';
import reportWebVitals from './reportWebVitals';

// Render components to DOM.
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
if (process.env.NODE_ENV === 'development') {
  // Use react-axe and StrictMode in development mode.
  import('@axe-core/react').then((axe) => {
    axe.default(React, root, 1000);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  });
} else {
  root.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
