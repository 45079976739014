/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-05
 */

// Add third-party dependencies.
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';

// Add local dependencies.
import Home from '../Home';
import Login from '../Auth/Login';
import ResetPassword from '../Auth/ResetPassword';
import Settings from '../Settings';
import SignUp from '../Auth/SignUp';
import NoMatch from './NoMatch';

/**
 * Router component.
 * @component
 * @example
 * return (
 *   <Router />
 * )
 * @returns {React.ReactElement} component.
 */
const Router = () => {
  // Render component.
  return (
    <Box component="main" flexGrow={1} sx={{ overflow: 'auto' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Box>
  );
};

// Export component.
export default Router;
