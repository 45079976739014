/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-02
 */

// Add third-party dependencies.
import { Box, Container, Typography } from '@mui/material';

// Add local dependencies.
import packagejson from '../../../package.json';

const { version } = packagejson;

/**
 * Footer component.
 * @component
 * @example
 * return (
 *   <Footer />
 * )
 * @returns {React.ReactElement} component.
 */
const Footer = () => {
  // Render component.
  return (
    <Box
      component="footer"
      p={2}
      mt="auto"
      sx={{
        color: 'text.primary',
        bgcolor: (theme) => (theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800]),
      }}
    >
      <Container maxWidth="false" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" align="center">
          Copyright © 2022 Little Shilling, Inc. All right reserved.
        </Typography>
        <Typography>{`v${version}`}</Typography>
      </Container>
    </Box>
  );
};

// Export component.
export default Footer;
