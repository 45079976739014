/**
 * Boston Consulting Group
 * Gamma Rapid Prototyping
 * Shon Little
 * May 19, 2020
 */

/**
 * Custom 404 error page.
 */

// Add third-party dependencies.
import { Link } from 'react-router-dom';
import { Box, Typography, Button, Paper } from '@mui/material';

// Add local dependencies.
import Venn from './Venn';

/**
 * Render NoMatch component to display 404 error page.
 * @component
 * @example
 * return (
 *   <NoMatch />
 * )
 * @returns {React.ReactElement} component.
 */
const NoMatch = () => {
  // Render component.
  return (
    <Box>
      <Box m={2}>
        <Typography variant="h1">Error 404</Typography>
        <Typography variant="h2">Page Not Found</Typography>
      </Box>
      <Paper p={3} elevation={4}>
        <Box m={2} align="center">
          <Typography variant="h3">Venn Diagram</Typography>
        </Box>
        <Box m={2} align="center">
          <Typography gutterBottom>
            Venn diagrams were introduced in 1880 by John Venn in a paper entitled &ldquo;On the Diagrammatic and
            Mechanical Representation of Propositions and Reasonings&rdquo; in the Philosophical Magazine and Journal
            of Science, about the different ways to represent propositions by diagrams.
          </Typography>
        </Box>
        <Box
          m={2}
          p={2}
          align="center"
          sx={{
            width: '90%',
            maxWidth: '600px',
            height: 'auto',
            maxHeight: '400px',
          }}
        >
          <Venn />
        </Box>
      </Paper>
      <Box m={2} align="center">
        <Button variant="contained" color="primary" component={Link} to="/">
          Please take me home
        </Button>
      </Box>
    </Box>
  );
};

// Export component.
export default NoMatch;
