/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-01
 */

// Add local dependencies.
import typography from './typography';

/**
 * Create Material-UI global dark theme.
 */
const dark = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#f44336',
      light: '#e57373',
      dark: '#d32f2f',
    },
    custom: {
      foreground: '#CD5D67',
      background: '#4F090B',
      primary: '#91171F',
      secondary: '#3E2F5B',
      success: '#76B041',
      danger: '#BA1F33',
      warning: '#FFC914',
      info: '#E4572E',
      light: '#FFFFFF',
      dark: '#000000',
      highlight: '#17BEBB',
      neutral: '#CFBBBC',
    },
  },
  typography,
};

// Export theme
export default dark;
