/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-05
 */

// Add third-party dependencies.
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Drawer, Toolbar, IconButton, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, Home as HomeIcon, Settings as SettingsIcon } from '@mui/icons-material';

// Add local dependencies.
import { DisplayContext } from '../../contexts/DisplayContext';

/**
 * Nav component.
 * @component
 * @example
 * return (
 *   <Nav />
 * )
 * @returns {React.ReactElement} component.
 */
const Nav = () => {
  // Context hooks.
  const { drawerOpen, setDrawerOpen } = useContext(DisplayContext);

  // Router hooks.
  const navigate = useNavigate();

  // Render component.
  return (
    <Box component="nav" display="flex" sx={{ minHeight: 'calc(100vh - 56px)' }}>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        sx={{
          ml: 1,
          '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: 250,
            transition: (theme) =>
              theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            boxSizing: 'border-box',
            ...(!drawerOpen && {
              overflowX: 'hidden',
              transition: (theme) =>
                theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                }),
              width: (theme) => theme.spacing(8),
            }),
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={() => setDrawerOpen(false)} aria-label="close menu drawer" size="large">
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="div">
          <ListItem button onClick={() => navigate('/')}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => navigate('/settings')}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

// Export component.
export default Nav;
