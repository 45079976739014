/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-03
 */

// Add third-party dependencies.
import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';

// Add local dependencies.
import { AuthContext } from '../../contexts/AuthContext';

/**
 * LoginButton component.
 * @component
 * @example
 * return (
 *   <LoginButton />
 * )
 * @returns {React.ReactElement} component.
 */
const LoginButton = () => {
  // State hooks.
  const [userInfo] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  // Context hooks.
  const { isAuthenticated } = useContext(AuthContext);

  // Router hooks.
  const navigate = useNavigate();

  // Menu position settings.
  const menuPosition = {
    vertical: 'top',
    horizontal: 'right',
  };

  /**
   * Logout event handler.
   */
  const handleLogout = () => {
    setMenuAnchorEl(null);
    // TODO
    // dispatch(logout());
    navigate('/login');
  };

  // Render component.
  return (
    <Box>
      <IconButton
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        color="inherit"
        aria-label="account of current user"
        size="large"
      >
        <AccountCircleIcon />
      </IconButton>
      {isAuthenticated ? (
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={menuPosition}
          transformOrigin={menuPosition}
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemText primary="Logout" secondary={userInfo && userInfo.name} />
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          anchorEl={menuAnchorEl}
          anchorOrigin={menuPosition}
          transformOrigin={menuPosition}
          open={!!menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem component={Link} to="/login" onClick={() => setMenuAnchorEl(null)}>
            Login
          </MenuItem>
          <MenuItem component={Link} to="/signup" onClick={() => setMenuAnchorEl(null)}>
            Sign Up
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

// Export component.
export default LoginButton;

/* <a
  class="MuiMenuItem-root MuiMenuItem-gutters MuiButtonBase-root css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root"
  tabindex="0"
  role="menuitem"
  href="/login"
>
  Login<span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
</a>; */
