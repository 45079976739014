/**
 * © 2022 Little Shilling, Inc.
 * Shon Little
 * Created: 2022-06-09
 */

// Add third-party dependencies.
import { useContext } from 'react';
import { Box, Typography, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';

// Add local dependencies.
import { DisplayContext } from '../../contexts/DisplayContext';

/**
 * Settings component.
 * @component
 * @example
 * return (
 *   <Settings />
 * )
 * @returns {React.ReactElement} component.
 */
const Settings = () => {
  // Context hooks.
  const { darkMode, toggleDarkMode } = useContext(DisplayContext);

  // Render component.
  return (
    <Box flexGrow={1} p={2}>
      <Typography variant="h1" sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <SettingsIcon sx={{ fontSize: 34, mr: 1 }} />
        Settings
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={<Switch checked={darkMode} onChange={toggleDarkMode} name="darkMode" />}
          label="Dark mode"
        />
      </FormGroup>
    </Box>
  );
};

// Export component.
export default Settings;
